import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [5,[2]],
		"/(app)/admin": [6,[2,3]],
		"/(app)/admin/access": [7,[2,3]],
		"/(app)/admin/activities": [8,[2,3]],
		"/(app)/admin/companies": [9,[2,3]],
		"/(app)/admin/companies/[id]": [10,[2,3]],
		"/(app)/admin/emails": [11,[2,3]],
		"/(app)/admin/miscellaneous": [12,[2,3]],
		"/(app)/admin/users": [13,[2,3]],
		"/(app)/admin/users/[id]": [14,[2,3]],
		"/(app)/billing": [15,[2]],
		"/(app)/billing/invoices/[id]": [16,[2]],
		"/(app)/cases/[id]": [17,[2]],
		"/(app)/cases/[id]/details": [18,[2]],
		"/(app)/devices/restricted": [~21,[2]],
		"/(app)/dev": [~19,[2]],
		"/(app)/dev/indexes/[uid]": [~20,[2]],
		"/(app)/forbidden": [22,[2]],
		"/(auth)/login": [25,[4]],
		"/(auth)/logout": [~26,[4]],
		"/(app)/map": [23],
		"/(app)/reports/[id]": [24,[2]],
		"/(auth)/signup": [~27,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';